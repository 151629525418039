<template>
  <custom-dialog :visible.sync="visible" :is-footer="isEdit" :title="title" @close="colseDialog" @submit="submit">
    <el-form :model="editForm" label-width="80px" :rules="rules"  ref="editHotelForm" >
      <el-row>
        <el-col :span="12">
          <el-form-item label="名称" prop="hotelName">
            <el-input size="small" :disabled="isEdit" v-model="editForm.hotelName" placeholder="请输入名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="楼层数" prop="hotelFloorNum">
            <el-input size="small" :disabled="isEdit" v-model="editForm.hotelFloorNum" placeholder="请输入楼层数"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="房间数" prop="hotelRoomNum">
            <el-input size="small" :disabled="isEdit" v-model="editForm.hotelRoomNum" placeholder="请输入房间数"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="负责人" prop="hotelHead">
            <el-input size="small" :disabled="isEdit" v-model="editForm.hotelHead" placeholder="请输入负责人"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="联系电话" prop="hotelTel">
            <el-input size="small" :disabled="isEdit" v-model="editForm.hotelTel" placeholder="请输入联系电话" maxLength="11"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="地址" prop="hotelAddress">
        <el-input size="small" :disabled="isEdit" v-model="editForm.hotelAddress" placeholder="请输入地址"></el-input>
      </el-form-item>
      <el-form-item label="备注" prop="hotelNote">
        <el-input size="small" :disabled="isEdit" type="textarea" v-model="editForm.hotelNote" placeholder="请输入备注"></el-input>
      </el-form-item>
      <el-form-item label="附件" prop="annexList">
        <el-upload
            class="file-uploader"
            :disabled="isEdit"
            :action="`${uploadUrl}File/upload`"
            :data="{type:1}"
            :show-file-list="false"
            :on-success="uploadSuccess">
          <div class="upload-main">
                  <span class="upload-btn">
                    <i class="el-icon-upload"></i>上传附件
                  </span>
            仅支持上传图片、pdf格式
          </div>
          <div class="file-url-main">
            <div v-for="(url,index) in editForm.annexList" class="file-url-item" :key="index">
              <div class="file-url" @click.stop="showImg(url)">{{url.annexUrl.match('--')? url.annexUrl.split('--')[1]: url.annexUrl}}</div>
              <span class="file-url-btn el-icon-close" circle @click.stop="delImg(index)"></span>
            </div>
          </div>
        </el-upload>
      </el-form-item>
    </el-form>

  </custom-dialog>
</template>

<script>
import CustomDialog from "../../common/CustomDialog";
import config from "../../../api/config";
import accommodation from "../../../api/modules/accommodation";
export default {
  name: "EditHotelDialog",
  components: {CustomDialog},
  props: ['visible', 'title', 'item','isEdit'],
  data(){
    return{
      editForm:{
        annexList:[]
      },
      rules: {
        hotelName: [
          { required: true, message: "请输入名称", trigger: "blur" },
        ],
        hotelFloorNum: [
          { required: true, message: "请输入楼层数", trigger: "blur" },
        ],
        hotelRoomNum: [
          { required: true, message: "请输入房间数", trigger: "blur" },
        ],
        hotelAddress: [
          { required: true, message: "请输入地址", trigger: "blur" },
        ],
      },
      uploadUrl: config.uploadUrl,
    }
  },
  watch:{
    item(newVal,oldVal){
      this.editForm = {...newVal}
    }
  },
  methods:{
    colseDialog(){
      this.$emit('close')
    },
    submit(){
      this.$refs.editHotelForm.validate((valid) => {
        if(valid){
          if(this.editForm.hotelId){
            accommodation.addHotel(this.editForm).then(res =>{
              this.$message({
                message: '编辑成功',
                type: 'success'
              });
              this.$emit('done')
            })
          }else {
            accommodation.addHotel(this.editForm).then(res =>{
              this.$message({
                message: '新增成功',
                type: 'success'
              });
              this.$emit('done')
            })
          }

        }
      })

    },
    uploadSuccess(val) {
      // 附件
      if (val.code == 200) {
        this.editForm.annexList.push({
          annexUrl:val.data,
          annexType: 1
        })
      }
    },
    delImg(index) {
      this.editForm.annexList.splice(index, 1)
      this.$forceUpdate()
    },
    showImg(item){
      let html = `<div class="img-alert"><img src="${item.annexUrl}"/></div>`
      this.$alert(html, '图片', {
        dangerouslyUseHTMLString: true
      });

    }
  }
}
</script>

<style scoped>

</style>
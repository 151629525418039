<template>
  <div class="pageMain">
    <search-head @reset="headReset" @search="headSearch">
      <el-form :inline="true" :model="searchForm">
        <el-form-item label="酒店名称">
          <el-input
              size="small"
              clearable
              v-model="searchForm.hotelName"
              placeholder="请输入酒店名称"
          ></el-input>
        </el-form-item>
      </el-form>
    </search-head>
    <custom-tables
        :data="tableData"
        :total="total"
        noimport="true"
        isdel="true"
        noexport="true"
        title="酒店列表"
        @selectionChange="selectionChange"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
        @tableDel="tableDel"
        @tableAdd="tableAdd">
      <el-table-column type="selection" align="center" width="55"></el-table-column>
      <el-table-column prop="hotelName" label="酒店名称" width="180"></el-table-column>
      <el-table-column prop="hotelAddress" label="地址"></el-table-column>
      <el-table-column prop="hotelHead" label="负责人"></el-table-column>
      <el-table-column prop="hotelTel" label="联系电话"></el-table-column>
      <el-table-column prop="hotelUseType" label="类别/用途"></el-table-column>
      <el-table-column prop="lastModifyTime" label="添加时间"></el-table-column>
      <el-table-column label="操作" align="center" width="180">
        <template slot-scope="scope">
          <span class="btn-icon" @click="delItem(scope.row)">删除</span>
          <span class="btn-icon" @click="editItem(scope.row)">查看附件</span>
        </template>
      </el-table-column>
    </custom-tables>
    <edit-hotel-dialog :visible.sync="showDialog"
                       :title="dialogTitle"
                       :item="item"
                       :is-edit="isEdit"
                       @close="showDialog = false"
                       @done="editDone">
    </edit-hotel-dialog>
  </div>
</template>

<script>
import accommodation from "../../../api/modules/accommodation";
import CustomTables from "../../../components/common/CustomTables";
import SearchHead from "../../../components/common/SearchHead";
import EditHotelDialog from "../../../components/page/accommodation/EditHotelDialog";

export default {
  name: "hotelManage",
  components: {EditHotelDialog, SearchHead, CustomTables},
  data() {
    return {
      dialogTitle: "新增酒店",
      showDialog: false,
      total: 0,
      tableData: [],
      searchForm: {
        pageNum: 1,
        pageSize: 10,
      },
      item: {},
      areaParentList: [],
      isEdit: false
    };
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      accommodation.getHotelByPage(this.searchForm).then((res) => {
        this.total = res.data.total;
        this.tableData = res.data.records;
      });
    },
    handleCurrentChange(pageIndex) {
      this.searchForm.pageNum = pageIndex;
      this.getInfo();
    },
    handleSizeChange(pageSize) {
      this.searchForm.pageNum = 1;
      this.searchForm.pageSize = pageSize;
      this.getInfo();
    },
    editDone() {
      // 编辑成功返回
      this.showDialog = false;
      this.getInfo();
    },
    headReset() {
      this.searchForm = {
        pageNum: 1,
        pageSize: 10,
      };
      this.getInfo();
    },
    headSearch() {
      this.searchForm.pageNum = 1;
      this.searchForm.pageSize = 10;
      this.getInfo();
    },
    tableDel() {
    },
    tableAdd() {
      this.item = {
        annexList:[]
      };
      this.isEdit = false
      this.dialogTitle = "新增酒店";
      this.showDialog = true;
    },
    selectionChange(val) {
      console.log(val);
    },
    editItem(item) {
      accommodation.getHotelAnnex({hotelId: item.hotelId}).then(res =>{
        this.item = item;
        this.isEdit = true
        this.item.annexList = res.data || []
        this.dialogTitle = "编辑酒店";
        this.showDialog = true;
      })
    },
    delItem(item) {
      this.$confirm(`此操作将永久删除该酒店信息, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        accommodation.delHotel({hotelId: item.hotelId}).then((res) => {
          this.$message({
            message: "删除成功",
            type: "success",
          });
          this.getInfo();
        });
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除",
        });
      });
    },
  },
};
</script>

<style scoped></style>
